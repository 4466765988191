import { useState, useEffect, useRef } from "react";

function useAbsolutePosition() {
  const ref = useRef(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const updatePosition = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        setPosition({
          top: rect.top,
          left: rect.left,
        });
      }
    };

    const handleScroll = () => {
      requestAnimationFrame(updatePosition);
    };

    updatePosition();

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", updatePosition);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updatePosition);
    };
  }, [ref]);

  return [ref, position];
}

export default useAbsolutePosition;
