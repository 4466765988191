import { handleLinkByType } from "@/helpers/common/handleLinkByType";
import useAbsolutePosition from "@/hooks/global/useAbsolutePosition";
import useOnClickOutsideV2 from "@/hooks/global/useOnClickOutsideV2";
import { urlToValidPath } from "@/utils/cms";
import { useRef, useState } from "react";

export default function Dropdown({ title, options, locale }) {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);
  const [ref, position] = useAbsolutePosition();
  useOnClickOutsideV2([menuRef], () => setOpen(false));
  console.log("options", options);
  return (
    <div className="relative ">
      <div
        ref={ref}
        onClick={() => setOpen(!open)}
        className="inline-flex items-center overflow-hidden rounded-xl border bg-white"
      >
        <p className="border-e px-4 py-2.5 max-sm:py-1.5 cursor-pointer text-sm/none font-bold max-sm:font-normal max-sm:text-xs text-nowrap text-gray-600 hover:bg-gray-50 hover:text-gray-700">
          {title}
        </p>

        <button className="h-full max-sm:py-1.5 p-2 text-gray-600 hover:bg-gray-50 hover:text-gray-700">
          {/* <span className="sr-only">Menu</span> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {
        open && (
          <div
            ref={menuRef}
            className={`fixed flex flex-col-reverse sm:flex-row z-modal `}
          >
            {/* <div className="h-screen sm:max-h-[60vh] sm:min-h-screen overflow-y-auto p-4 max-sm:pt-2 bg-white max-sm:w-full w-[450px] "> */}
            <div
              className={` mt-2 w-40 fixed rounded-md top-0 border border-gray-100 bg-white shadow-lg`}
              // role="menu"
              style={{
                top: `${position.top + 30}px`,
                left: `${position.left + 10}px`,
              }}
            >
              {options?.map((option) => (
                <div key={option["url-ar"]} className="p-2">
                  <a
                    href={
                      option.type === "cms"
                        ? urlToValidPath(
                            option["title-en"],
                            option[`url-${locale}`]
                          )
                        : handleLinkByType(option?.url, option?.type)
                    }
                    className="block rounded-lg cursor-pointer min-md:font-bol px-4 py-2 text-sm max-sm:text-xs text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                    role="menuitem"
                  >
                    {locale == "en" ? option["title-en"] : option["title-ar"]}
                  </a>
                </div>
              ))}
            </div>
          </div>
        )
        // </div>
      }
    </div>
  );
}
