import { handleLinkByType } from "@/helpers/common/handleLinkByType";
import Link from "next/link";
import React from "react";

const StaticBadges = ({ data, language }) => {
  const sortedData = data?.value?.sort((a, b) => +a.position - +b?.position);
  return (
    data?.value?.length > 0 &&
    (data?.active || data?.status) && (
      <section
        className={`w-11/12 mx-auto my-4 flex ${
          sortedData?.length < 7 ? "md:justify-center items-center" : ""
        } gap-5 overflow-x-auto no-scrollbar`}
      >
        {sortedData?.map((item, index) => (
          <Link
            href={handleLinkByType(item?.url, item?.type)}
            key={index}
            className={`rounded-xl text-center bg-primary hover:bg-orange-500 py-2 font-medium max-sm:text-xs text-nowrap sm:font-extrabold px-7 text-white`}
          >
            {item[`title-${language}`] || ""}
          </Link>
        ))}
      </section>
    )
  );
};

export default StaticBadges;
