export const handleLinkByType = (link, type) => {
  switch (type) {
    case "url":
      return `${link}`;
    case "banner":
      return `${link}`;
    case "product":
      return `/product/${link}`;
    case "product_slider":
      return `/product/${link}`;
    case "product_banner":
      return `/product/${link}`;
    case "category":
      return `/${link}`;
    case "category_banner":
      return `/${link}`;
    case "category_slider":
      return `/${link}`;
    default:
      return ``;
  }
};
