import { urlToValidPath } from "@/utils/cms";
import { sortByProperty } from "@/utils/global";
import { useRouter } from "next/router";
import Link from "next/link";
import Dropdown from "../global/dropdown/Dropdown";
import { handleLinkByType } from "@/helpers/common/handleLinkByType";

const StaticPages = ({ data, language }) => {
  const { locale } = useRouter();

  const getActiveSortedItems = (items, property) => {
    const activeItems = items?.value?.filter((item) => item?.active) || [];
    return sortByProperty(activeItems, property);
  };

  // Get and sort top links installment options
  const topLinksInstallmentOptionsSorted = getActiveSortedItems(
    data?.top_links_installment,
    "position"
  );

  // Get and sort top links
  const topLinksSorted = getActiveSortedItems(data?.top_links, "position");

  return (
    (topLinksInstallmentOptionsSorted?.length > 0 ||
      topLinksSorted?.length > 0) && (
      <section
        className={`py-3 bg-[#F2F1F1] min-h-fit px-14 max-sm:px-3 no_scroll-640`}
      >
        <div className="w-11/12 min-h-fit flex overflow-auto gap-4 md:justify-center">
          {topLinksInstallmentOptionsSorted?.length > 0 && (
            <Dropdown
              locale={locale}
              title={data?.top_links_installment?.[`title-${locale}`] || ""}
              options={topLinksInstallmentOptionsSorted}
            />
          )}

          {topLinksSorted?.length > 0 &&
            topLinksSorted?.map((item, index) => (
              <Link
                href={
                  item.type === "cms"
                    ? urlToValidPath(
                        item["title-en"],
                        locale == "en" ? item["url-en"] : item["url-ar"]
                      )
                    : handleLinkByType(item?.url, item?.type)
                }
                key={index}
                className={`rounded-xl text-center bg-white hover:bg-slate-100 py-1 sm:py-2 max-w-fit px-4 text-xs max-sm:px-7 text-nowrap border  sm:text-sm font-medium sm:font-extrabold sm:flex-1 text-blue-dark`}
              >
                {item[`title-${language}`] || ""}
              </Link>
            ))}
        </div>
      </section>
    )
  );
};

export default StaticPages;
