import Link from "next/link";
import Image from "@/components/Image";
import { useRouter } from "next/router";
import { event_select_promotion } from "@/utils/gtm";

const CategoryCardCircle = ({data, img, name, url = "/", fromCategory, id, ref = null }) => {
  const { query } = useRouter();
  return (
    <Link
      onClick={() => {
        event_select_promotion({
          promotionImage: img,
          key: data?.key,
          position: data?.position,
          id: id,
          name: name,
        })
      }}
      href={
        fromCategory ? Object.values(query)?.join("/").concat(`/${url}`) : url
      }
      className={`flex flex-col justify-between h-full gap-2 ${
        !fromCategory ? "p-3" : "p-1 mx-px"
      } rounded-full cursor-pointer min-h-32 transition-transform transform hover:scale-110`}
    >
      <Image
        loading="eager"
        src={img}
        ref={ref}
        className="object-contain mx-auto"
        alt={name}
        title={name}
      />
    </Link>
  );
};

export default CategoryCardCircle;
