import Image from "@/components/Image";
import Arrow from "@/components/icons/Arrow";
import { handleLinkByType } from "@/helpers/common/handleLinkByType"
import useInViewPort from "@/hooks/global/useInViewPort";
import { event_select_promotion, event_view_promotion } from "@/utils/gtm";
import { useRouter } from "next/router";

import { useEffect, useState } from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// import { MainBannerItem } from "./MainBannerItem";

const MainBanner = ({ data, language }) => {
  const [swiper, set_swiper] = useState({});
  const { push } = useRouter();
  console.log("first", data.value);
  const banners = (data?.value || [])
    .filter((bnr) => bnr?.active)
    .sort((a, b) => a.position - b.position);
    const [isMobile, setIsMobile] = useState(false);
    const fireViewPromotion = () => {if (data?.value?.length > 0) {
      const updatedData = data?.value?.map(item => ({
        promotionImage: item?.[`img-${language}`],
        key: data?.key,
        position: data?.position,
        id: item?.id,
        name: item?.[`title-${language}`],
      }))
      event_view_promotion(updatedData);
    }}
     const [ref] = useInViewPort(fireViewPromotion);
     useEffect(() => {
      const checkScreenSize = () => {
        setIsMobile(window.innerWidth < 768);
      };
      checkScreenSize(); // Check on initial load
      window.addEventListener("resize", checkScreenSize);
  
      return () => window.removeEventListener("resize", checkScreenSize);
    }, []);
  return (
    banners?.length > 0 && (
      <section className="relative w-12/12 mx-auto p-5">
        <Swiper
          key={language}
          modules={[Autoplay]}
          // watchOverflow
          autoplay={{ delay: 8000, disableOnInteraction: true }}
          dir={language === "en" ? "ltr" : "rtl"}
          onInit={(e) => set_swiper(e)}
          loop={data?.value?.length > 3 ? true : false}
          observeParents={true}
          observer={true}
        >
          {banners.map(
            (banner, idx) =>
              (banner?.active || banner?.status) && (
                <SwiperSlide key={idx}>
                  <Image
                    loading={idx <= 2 ? "eager" : "lazy"}
                    priority
                    ref={ref}
                    src={
                      banner[`img-${language}`].startsWith("http")
                        ? banner[`img-${language}`]
                        : `${process.env.NEXT_PUBLIC_IMG_BASEURL}/json/images/${
                            banner[`img-${language}`]
                          }`
                    }
                    className="w-full h-auto object-contain cursor-pointer rounded-lg"
                    quality={70}
                    width={isMobile ? 724 : 1342}
                    height={isMobile ? 724 : 362}
                    onClick={() => {
                      event_select_promotion({
                        promotionImage: banner[`img-${language}`],
                        key: data?.key,
                        position: data?.position,
                        id: banner?.id,
                        name: banner?.[`title-${language}`],
                      })
                      push(handleLinkByType(banner?.url, banner?.type))
                    }}
                    alt={banner[`title-${language}`]}
                  />
                </SwiperSlide>
              )
          )}

          {/* {data?.value.map(
            (banner, idx) =>
              banner?.status && (
                <MainBannerItem
                  key={idx}
                  {...{ banner, language, push, index: idx }}
                />
              )
          )} */}
        </Swiper>
        <button
          onClick={() => swiper?.slidePrev()}
          className="absolute z-20 items-center justify-center hidden p-2 bg-white rounded-full shadow-lg top-[calc(50%-18px)] left-2 size-9 md:block"
        >
          <Arrow className="rotate-180" />
        </button>
        <button
          onClick={() => swiper?.slideNext()}
          className="absolute z-20 items-center justify-center hidden p-2 bg-white rounded-full shadow-lg top-[calc(50%-18px)] right-2 size-9 md:block"
        >
          <Arrow />
        </button>
      </section>
    )
  );
};

export default MainBanner;
